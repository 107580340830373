import { useAxios } from '@/hooks/useAxios'

const request = useAxios()

// 客户列表
export const list_enterprise = (data) => {
    return request.post({
        url: '/ent/enterprise/list_enterprise',
        data,
    })
}

// 企业详情
export const get_enterprise = (data) => {
    return request.post({
        url: '/ent/enterprise/get_enterprise',
        data,
    })
}

// 已选客户经理
export const selected_manager = (data) => {
    return request.post({
        url: '/ent/enterprise/selected_manager',
        data,
    })
}

// 添加标签
export const add_tag = (data) => {
    return request.post({
        url: '/ent/enterprise/add_tag',
        data,
    })
}

// 黑名单状态
export const black_list_status = (data) => {
    return request.post({
        url: '/ent/enterprise/black_list_status',
        data,
    })
}

// 可选客户经理
export const selectable_manager = (data) => {
    return request.post({
        url: '/ent/enterprise/selectable_manager',
        data,
    })
}

// 修改客户经理
export const change_manager = (data) => {
    return request.post({
        url: '/ent/enterprise/change_manager',
        data,
    })
}

// 审核不通过
export const un_approved = (data) => {
    return request.post({
        url: '/ent/enterprise/un_approved',
        data,
    })
}

// 审核通过
export const approved = (data) => {
    return request.post({
        url: '/ent/enterprise/approved',
        data,
    })
}

// 企业信息操作记录
export const list_ent_operate = (data) => {
    return request.post({
        url: '/ent/enterprise/list_ent_operate',
        data,
    })
}