<template>
  <div>
    <el-table
      :data="props.tableData2"
      style="width: 100%; height: calc(100vh - 280px)"
      :border="1"
    >
      <el-table-column label="操作时间" min-width="100px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.operate_time || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作账号" min-width="100px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.admin_name || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作类型" min-width="100px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.operate_type || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作字段" min-width="100px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.operate_field || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="变更前" min-width="100px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.before_change || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="变更后" min-width="100px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>
            {{ scope.row.after_change || "暂无"
            }}<span
              v-if="scope.row.after_change == '审核不通过'"
              style="cursor: pointer; margin-left: 5px"
              @click="check(scope.row.note)"
              >(审核意见)</span
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog v-model="dialogVisible" width="500px" title="审核意见">
      <div>{{ note }}</div>
      <div style="text-align: right; margin-top: 30px">
        <el-button v-debounce type="primary" @click="dialogVisible = false"
          >确认</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { defineProps, ref } from "vue";
const props = defineProps(["tableData2"]);

const dialogVisible = ref(false);
const note = ref("");

const check = (value) => {
  note.value = value;
  dialogVisible.value = true;
};
</script>

<style scoped></style>
