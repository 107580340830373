<template>
  <div style="text-align:center">
    <div v-if="props.type == 1">
      <div>移出黑名单后，客户可正常发起业务，是否将客户移出黑名单？</div>
      <div style="text-align:right;margin-top:50px">
        <el-button v-debounce type="primary" @click="operateBlack(1)">确定</el-button>
        <el-button v-debounce @click="emit('dialogClose')">取消</el-button>
      </div>
    </div>
    <div v-if="props.type == 2" style="text-align:left">
      <div>列入黑名单后，该客户只能查询现有数据，不发起新业务，是否列入黑名单？</div>
      <div style="margin-top:10px;padding-left:5px"> 新业务包括：申报、核销经办、上传关单等操作</div>
      <div style="text-align:right;margin-top:50px">
        <el-button v-debounce type="primary" @click="operateBlack(2)">确定</el-button>
        <el-button v-debounce @click="emit('dialogClose')">取消</el-button>
      </div>
    </div>
    <div v-if="props.type == 3" style="text-align:left">
      <div style="margin-bottom:15px">请为客户分配客户经理：</div>
      <div style="padding-left:15px">
        <div class="flex">
          <div class="custom">当前客户经理：</div>
          <el-tag v-for="item in MSelect" :key="item">{{item}}</el-tag>
        </div>
        <div style="margin: 20px 0" class="flex">
          <div class="custom">新分配客户经理：</div>
          <el-tag @close="closeTags(item)" v-for="item in SMList" :key="item" closable style="margin-right:5px">{{ getLabelById(item) }}</el-tag>
        </div>
        <div class="flex">
          <div class="custom">添加客户经理：</div>
          <el-select v-model="SMList" multiple collapse-tags collapse-tags-tooltip style="width: 200px;margin-top:-5px" clearable>
            <el-option v-for="item in props.CMS" :key="item.admin_id" :label="item?.admin_name" :value="item.admin_id" />
          </el-select>
        </div>
      </div>
      <div style="text-align:right;margin-top:30px">
        <el-button v-debounce type="primary" @click="changeManager">确定分配</el-button>
        <el-button v-debounce @click="emit('dialogClose');initData()">取消</el-button>
      </div>
    </div>
    <div v-if="props.type == 4">
      <div style="text-align:left;margin-top: -20px">
        <div>审核意见</div>
        <div style="margin: 50px 0">{{props?.accessMsg}}</div>
      </div>
      <div style="text-align:right;margin-top:30px">
        <el-button v-debounce @click="emit('dialogClose')">关闭</el-button>
      </div>
    </div>
    <div v-if="props.type == 5">
      <div style="text-align:left;margin-top: -20px">
        <div>请填写审核意见：</div>
        <div style="margin: 10px 0;display:flex;align-items:center"><el-icon style="color:#337ecc;font-size:18px;margin-right:5px"><WarningFilled /></el-icon>请填写企业认证审核意见，企业可根据审核意见修改后重新提交认证</div>
        <el-input type="textarea" :rows="5" v-model="note"></el-input>
      </div>
      <div style="text-align:right;margin-top:30px">
        <el-button v-debounce type="primary" @click="unApprovedIt">确定</el-button>
        <el-button v-debounce @click="emit('dialogClose')">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref, defineExpose } from 'vue'
import { black_list_status, change_manager, un_approved } from '@/api/bank/customer.js'
import { ElMessage } from 'element-plus'
import { WarningFilled } from '@element-plus/icons-vue'


const props = defineProps(['type', 'rowId', 'managerSelectList', 'CMS', 'accessMsg'])
const emit = defineEmits(['dialogClose','callBack'])

// 操作黑名单
const operateBlack = (status) => {
  black_list_status({
    ent_id: props.rowId,
    status
  })
  .finally(() => {
    emit('dialogClose')
    emit('callBack')
  })
}

const note = ref('')
const unApprovedIt = async () => {
  let res = await un_approved({
    ent_id: props.rowId,
    note: note.value
  })
  if(res?.code == 200) {
    emit('dialogClose')
    emit('callBack')
    ElMessage({
      grouping: true, 
      showClose: true,
      message: res.msg || '操作成功',
      type: 'success',
    })
  }
}

const SMList = ref([])
// 更换客户经理
const changeManager = async () => {
  let res = await change_manager({
    ent_id: props.rowId,
    admin_list: SMList.value
  })
  if(res?.code == 200) {
    initData()
    emit('dialogClose')
    emit('callBack')
    ElMessage({
      grouping: true, 
      showClose: true,
      message: res.msg || '操作成功',
      type: 'success',
    })
  }
}

const closeTags = (tag) => {
  SMList.value = SMList.value.filter(item => item != tag)
}

const getLabelById = (id) => {
  const item = props.CMS.filter(item => item.admin_id == id)
  return item[0]?.admin_name
}

const MSelect = computed(() => {
  return props.managerSelectList.map(item => item?.admin_name)
})

const initData = () => {
  SMList.value = []
}

defineExpose({
  initData
})
</script>

<style scoped>
.flex{
  display: flex;
}
.custom{
  width: 120px;
  text-align:right;
}
</style>