<template>
  <div class="check">
    <div class="title">
      <div class="title_left">
        <div class="flex">
          <div class="flexBox">
            <div class="flexTitle">商户名称</div>
            <el-input v-model="search_param.ent_name"></el-input>
          </div>
          <div class="flexBox">
            <div class="flexTitle">状态</div>
            <el-select
              v-model="search_param.status"
              placeholder="全部状态"
              style="width: 100%"
              clearable
            >
              <el-option
                v-for="item in auditOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </div>
          <div class="flexBox">
            <div class="flexTitle">客户经理</div>
            <el-input v-model="search_param.manager_name"></el-input>
          </div>
        </div>
      </div>
      <div class="title_right">
        <el-button
          v-debounce
          type="primary"
          style="margin-left: auto"
          @click="getList"
          >查询</el-button
        >
        <el-button v-debounce @click="reset">重置</el-button>
      </div>
    </div>
    <el-table
      :data="tableData"
      :border="1"
      :header-cell-style="{ 'background-color': '#f6f6f6' }"
      :cell-style="{ fontSize: '12px' }"
      style="width: 100%; height: calc(100vh - 200px)"
      @header-dragend="headerDragend"
      @selection-change="handleSelectionChange1"
      @row-click="rowClick"
    >
      <el-table-column type="selection" label="序号" width="55" />
      <el-table-column label="状态" min-width="80px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>
            <span>{{
              scope.row.use_status == 1
                ? "正常"
                : scope.row.use_status == 2
                ? "异常"
                : "暂无"
            }}</span>
            <span v-if="scope.row.use_status == 2" class="blackMenu"
              >黑名单</span
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column label="审核状态" min-width="100px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.enable || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="商户名称" min-width="120px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.ent_name || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="客户经理" min-width="80px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.admin_name || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="认证类型" min-width="80px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.enterprise_type || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column label="标签" min-width="100px" show-overflow-tooltip>
        <template v-slot:default="scope">
          <div>{{ scope.row.tag || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="最近登录时间"
        min-width="120px"
        show-overflow-tooltip
      >
        <template v-slot:default="scope">
          <div>{{ scope.row.first_login_time || "暂无" }}</div>
        </template>
      </el-table-column>
      <el-table-column
        label="首次登录时间"
        min-width="120px"
        show-overflow-tooltip
      >
        <template v-slot:default="scope">
          <div>{{ scope.row.last_login_time || "暂无" }}</div>
        </template>
      </el-table-column>
    </el-table>
    <div class="demo-pagination-block">
      <el-pagination
        v-model:current-page="currentPage"
        :page-sizes="[10, 30, 50, 100, 500]"
        v-model:page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
      />
    </div>
    <!-- 抽屉详情 -->
    <el-drawer
      v-model="showDrawer"
      :with-header="false"
      direction="btt"
      size="100%"
    >
      <div>
        <div style="margin-bottom: 10px">
          <span style="font-weight: 700; font-size: 15px; cursor: pointer">
            <span @click="showDrawer = false"
              ><el-icon style="position: relative; top: 2px"
                ><ArrowLeftBold /></el-icon
              >返回</span
            >
            <span style="margin: 0 10px">|</span>
            <span>客户详情</span>
          </span>
          <div style="float: right">
            <span
              v-if="rowDetail.enable == '未审核'"
              style="margin-right: 10px"
            >
              <el-button
                v-debounce
                v-disabled="{ name: 'change_manager' }"
                type="primary"
                @click="passOrder"
                style="margin-top: -3px"
                >审核不通过</el-button
              >
              <el-button
                v-debounce
                v-disabled="{ name: 'change_manager' }"
                type="primary"
                @click="accessOrder"
                style="margin-top: -3px"
                >审核通过</el-button
              >
            </span>
            <span>
              <el-button
                v-debounce
                v-disabled="{ name: 'change_manager' }"
                type="primary"
                @click="dispatchManager"
                style="margin-top: -3px"
                >分配客户经理</el-button
              >
              <span style="margin: 0 10px 0 20px">是否列入黑名单</span>
              <el-switch
                v-model="rowDetail.use_status_type"
                :disabled="!Auth.includes('black_list_status')"
                :before-change="switchChange"
              />
            </span>
          </div>
        </div>
        <el-divider />
        <div>
          <span>客户名称：{{ rowDetail.ent_name }}</span>
          <span style="margin: 0 40px">
            状态：{{
              rowDetail.use_status == "正常"
                ? "正常"
                : rowDetail.use_status == "黑名单"
                ? "异常"
                : "暂存"
            }}
            <span class="blackMenu" v-if="rowDetail.use_status == '黑名单'"
              >黑名单</span
            >
          </span>
          <span style="margin-right: 40px"
            >认证审核状态：{{ rowDetail.enable }}</span
          >
          <span
            >客户经理：{{
              managerSelectList.map((item) => item.admin_name).join(",") ||
              "暂无"
            }}</span
          >
        </div>
        <el-tabs
          v-model="activeName"
          class="demo-tabs"
          @tab-click="handleClick"
          style="margin-top: 10px"
        >
          <el-tab-pane label="客户认证详情" name="first">
            <div class="detail">
              <!-- 认证通过/不通过 -->
              <div
                v-if="
                  rowDetail.enable == '审核通过' ||
                  rowDetail.enable == '审核不通过'
                "
              >
                <div>
                  <span
                    >统一社会信用代码：{{ rowDetail.ebc_code
                    }}<span style="cursor: pointer" @click="openImg"
                      >（查看营业执照）</span
                    ></span
                  >
                  <span style="margin: 0 30px"
                    >企业联系人：{{ rowDetail.contacts }} （{{
                      rowDetail.contacts_phone
                    }}）</span
                  >
                  <span>企业联系邮箱：{{ rowDetail.email }}</span>
                </div>
                <div style="margin: 20px 0">
                  <span>认证提交时间：{{ rowDetail.create_time }}</span>
                  <span style="margin: 0 40px"
                    >认证通过时间：{{ rowDetail.enable_time || "" }}</span
                  >
                  <span style="margin-right: 40px"
                    >海关登记代码：{{
                      rowDetail.enterprise_record_no || ""
                    }}</span
                  >
                  <span>dxp：{{ rowDetail.dxp || "" }}</span>
                </div>
                <div>认证类型：{{ rowDetail.enterprise_type }}</div>
              </div>
              <!-- 认证待审核/待重审 -->
              <div v-if="rowDetail.enable == '未审核'">
                <div>
                  <span
                    >统一社会信用代码：{{ rowDetail.ebc_code
                    }}<span style="cursor: pointer" @click="openImg"
                      >（查看营业执照）</span
                    ></span
                  >
                  <span style="margin: 0 30px"
                    >企业联系人：{{ rowDetail.contacts }} （{{
                      rowDetail.contacts_phone
                    }}）</span
                  >
                  <span>企业联系邮箱：{{ rowDetail.email }}</span>
                </div>
                <div style="margin: 20px 0">
                  <span>认证类型：{{ rowDetail.enterprise_type }}</span>
                  <span style="margin: 0 40px">DXP：{{ rowDetail.dxp }}</span>
                  <span>海关登记代码：{{ rowDetail.ent_code }}</span>
                </div>
                <div>
                  <span>认证提交时间：{{ rowDetail.create_time }}</span>
                  <span style="margin: 0 40px"
                    >认证通过时间：{{ rowDetail.enable_time || "" }}</span
                  >
                  <span style="margin-right: 40px"
                    >海关登记代码：{{
                      rowDetail.enterprise_record_no || ""
                    }}</span
                  >
                  <span>dxp：{{ rowDetail.dxp || "" }}</span>
                </div>
              </div>
              <div style="margin: 10px 0">
                <span>业务类型：</span>
                <span>
                  <el-checkbox
                    v-if="String(rowDetail.declare_type).indexOf('9610') > -1"
                    disabled
                    checked
                    label="9610"
                    size="large"
                  />
                  <el-checkbox v-else label="9610" size="large" disabled />
                  <el-checkbox
                    v-if="String(rowDetail.declare_type).indexOf('9710') > -1"
                    checked
                    label="9710"
                    size="large"
                    disabled
                  />
                  <el-checkbox v-else label="9710" size="large" disabled />
                </span>
              </div>
              <div style="display: flex; flex-wrap: wrap">
                <div style="width: 70px">客户标签：</div>
                <div style="flex: 1" v-if="entTag">
                  <el-tag
                    v-for="tag in entTag"
                    :key="tag"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(tag)"
                    style="margin: 0 8px 8px 0"
                    effect="plain"
                  >
                    {{ tag }}
                  </el-tag>
                  <el-input
                    v-if="inputVisible"
                    v-disabled="{ name: 'add_tag' }"
                    ref="InputRef"
                    v-model="inputValue"
                    size="small"
                    @keyup.enter="handleInputConfirm"
                    @blur="handleInputConfirm"
                    style="width: 100px; height: 25px"
                    clearable
                  />
                  <el-button
                    v-else
                    :icon="Plus"
                    v-disabled="{ name: 'add_tag' }"
                    circle
                    type="primary"
                    size="small"
                    @click="showInput"
                  ></el-button>
                </div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="操作记录" name="second">
            <Record :rowId="rowId" :tableData2="tableData2" />
            <div class="demo-pagination-block">
              <el-pagination
                v-model:current-page="currentPage2"
                :page-sizes="[10, 30, 50, 100, 500]"
                v-model:page-size="pageSize2"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalCount2"
                @current-change="handleCurrentChange2"
                @size-change="handleSizeChange2"
              />
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-drawer>
    <!-- 操作 -->
    <el-dialog v-model="dialogVisible" width="530px" :show-close="false">
      <DialogMessage
        :type="type"
        :rowId="rowId"
        :managerSelectList="managerSelectList"
        @dialogClose="dialogVisible = false"
        @callBack="callBack"
        :CMS="CMS"
        ref="DMessage"
      />
    </el-dialog>
    <!-- 图片 -->
    <el-dialog v-model="imgVisible" width="60%" title="图片预览">
      <div style="width: 100%; min-height: 300px">
        <img :src="imgUrl" style="width: 100%" />
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, nextTick } from "vue";
import {
  list_enterprise,
  get_enterprise,
  selected_manager,
  add_tag,
  selectable_manager,
  list_ent_operate,
  approved,
} from "@/api/bank/customer.js";
import { ElMessage } from "element-plus";
import { Plus } from "@element-plus/icons-vue";
import { ArrowLeftBold } from "@element-plus/icons-vue";
import { useCache } from "@/hooks/useCache";
import Record from "./components/Record";
import DialogMessage from "./components/DialogMessage";

//查询参数对象
const search_param = reactive({
  ent_name: "",
  manager_name: "",
  status: "",
  page: 1,
  size: 10,
});

// 重置功能
const reset = () => {
  (search_param.ent_name = ""),
    (search_param.manager_name = ""),
    (search_param.status = "");
};

//审核状态选择下拉框信息
const auditOptions = ref([
  { value: 1, label: "正常" },
  { value: 2, label: "黑名单" },
]);

//分页逻辑
const currentPage = ref(1);
const pageSize = ref(10);
const totalCount = ref(0);
const handleCurrentChange = (val) => {
  currentPage.value = val;
  getList({ cP: currentPage.value, size: pageSize.value, tips: true });
};
const handleSizeChange = (val) => {
  pageSize.value = val;
  getList({ cP: currentPage.value, size: pageSize.value, tips: true });
};

// 表格数据
let tableData = ref([]);
let multipleSelection1 = ref([]);
const handleSelectionChange1 = (val) => {
  multipleSelection1.value = val;
};
//获得数据列表
const getList = ({ cP = 1, size = 10, tips = false }) => {
  currentPage.value = cP;
  pageSize.value = size;
  list_enterprise({
    ent_name: search_param.ent_name,
    manager_name: search_param.manager_name,
    status: search_param.status,
    page: cP,
    size: size,
  }).then((res) => {
    if (res.code == 200) {
      tableData.value = res.data.result;
      if (!tips)
        ElMessage({
          grouping: true,
          showClose: true,
          message: res.msg,
          type: "success",
        });
    }
    totalCount.value = res.data.count;
  });
};

const showDrawer = ref(false);
const activeName = ref("first");
let rowId = ref("");

const { wsCache } = useCache();
const Auth = wsCache.get("Auth");
const rowClick = async (row) => {
  if (!Auth.includes("get_enterprise")) return;

  showDrawer.value = true;
  rowId.value = row.ent_id;
  DMessage?.value?.initData();
  getManager();
  await getDetail();
  getListEntOperate({ cP: 1, size: 10, tips: true });
};

const handleClick = (tab) => {
  if (tab.props.name == "second")
    getListEntOperate({ cP: 1, size: 10, tips: true });
};

const DMessage = ref(null);

//分页逻辑
const tableData2 = ref([]);
const currentPage2 = ref(1);
const pageSize2 = ref(10);
const totalCount2 = ref(0);
const handleCurrentChange2 = (val) => {
  currentPage2.value = val;
  getListEntOperate({
    cP: currentPage2.value,
    size: pageSize2.value,
    tips: true,
  });
};
const handleSizeChange2 = (val) => {
  pageSize2.value = val;
  getListEntOperate({
    cP: currentPage2.value,
    size: pageSize2.value,
    tips: true,
  });
};
// 获取
const getListEntOperate = async ({ cP = 1, size = 10, tips = false }) => {
  let res = await list_ent_operate({
    ent_name: rowDetail.value.ent_name,
    page: cP,
    size: size,
  });
  if (res.code == 200) {
    totalCount2.value = res.data.count;
    tableData2.value = res.data.result;
    if (!tips)
      ElMessage({
        grouping: true,
        showClose: true,
        message: res.msg,
        type: "success",
      });
  }
};

const rowDetail = ref({});
const entTag = ref([]);
// 获取详情
const getDetail = async () => {
  let res = await get_enterprise({ ent_id: rowId.value });
  if (res.code == 200) {
    rowDetail.value = res.data.info;
    if (rowDetail.value.use_status == "正常") {
      rowDetail.value.use_status_type = false;
    } else {
      rowDetail.value.use_status_type = true;
    }
    if (res.data.info.ent_tag == "") return (entTag.value = []);
    entTag.value = res.data.info.ent_tag.split(",");
  }
};

const managerSelectList = ref([]);
const getManager = async () => {
  let res = await selected_manager({ ent_id: rowId.value });
  if (res.code == 200) {
    managerSelectList.value = res.data.result;
  }
};

// 增加标签
const addTags = async () => {
  let res = await add_tag({
    ent_id: rowId.value,
    tag: entTag.value.join(",") || "",
  });
  if (res.code == 200) {
    ElMessage({
      grouping: true,
      showClose: true,
      message: "操作成功",
      type: "success",
    });
  }
};

// tag标签相关
const inputValue = ref("");
const inputVisible = ref(false);
const InputRef = ref();
const handleClose = async (tag) => {
  if (!Auth.includes("add_tag")) return;
  entTag.value.splice(entTag.value.indexOf(tag), 1);
  await addTags();
  getDetail();
};
const showInput = () => {
  inputVisible.value = true;
  nextTick(() => {
    InputRef.value.input.focus();
  });
};
const handleInputConfirm = async () => {
  if (inputValue.value) {
    entTag.value.push(inputValue.value);
    inputVisible.value = false;
    inputValue.value = "";
    await addTags();
    getDetail();
  }
  inputVisible.value = false;
  inputValue.value = "";
};

const switchChange = () => {
  if (rowDetail.value.use_status_type) {
    type.value = 1;
  } else {
    type.value = 2;
  }
  dialogVisible.value = true;
  return false;
};

const accessOrder = async () => {
  let res = await approved({
    ent_id: rowId.value,
  });
  if (res.code == 200) {
    ElMessage({
      grouping: true,
      showClose: true,
      message: res.msg || "操作成功",
      type: "success",
    });
  }
};

const passOrder = () => {
  type.value = 5;
  dialogVisible.value = true;
};

const dispatchManager = () => {
  type.value = 3;
  dialogVisible.value = true;
  getManager();
  selectableManager();
};

const CMS = ref([]);
const selectableManager = async () => {
  let res = await selectable_manager({ ent_id: rowId.value });
  if (res.code == 200) CMS.value = res.data.result;
};

const callBack = async () => {
  await getDetail();
  getList({ cP: currentPage.value, size: pageSize.value, tips: true });
};

// 分配内容
const dialogVisible = ref(false);
const type = ref("");

const imgVisible = ref(false);
const imgUrl = ref("");
const openImg = () => {
  if (rowDetail.value.license_url) {
    imgUrl.value = rowDetail.value.license_url;
    imgVisible.value = true;
  } else {
    ElMessage({
      grouping: true,
      showClose: true,
      message: "暂无图片",
      type: "error",
    });
  }
};

// 拉伸表格
const headerDragend = (row, column, cell) => {
  if (row < cell.minWidth) cell.width = cell.minWidth;
};
</script>

<style scoped>
.check {
  color: #666;
  font-size: 14px;
}
.title {
  display: flex;
}
.title_left {
  flex: 1;
}
.title_right {
  float: right;
}
.flex {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.flexBox {
  flex: 1;
  display: flex;
  line-height: 30px;
  text-align: right;
  margin-right: 50px;
}
.flexTitle {
  width: 110px;
  text-align: center;
}
.tableHeader {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
}
/*分页样式 */
.demo-pagination-block {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blackMenu {
  background-color: #000;
  color: #fff;
  padding: 2px 4px;
  margin-left: 10px;
  font-size: 10px;
}
:deep(.el-tabs__nav-wrap::after) {
  background-color: #fff !important;
}
.demo-tabs > .el-tabs__content {
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
:deep(.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner) {
  background-color: var(--el-checkbox-checked-bg-color);
  border-color: var(--el-checkbox-checked-input-border-color);
}
:deep(.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after) {
  border-color: #fff;
}
:deep(.el-checkbox__input.is-disabled + span.el-checkbox__label) {
  color: #606266;
}
</style>
